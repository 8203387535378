body {
  margin: 0;
  padding: 0;
}

.centered {
  text-align: center;
}

.fullWidth {
  width: 100%;
}

.padding5 {
  padding: 10px;
}

.loading.tractor {
  margin: auto;
  max-height: 200px;
  max-width: 200px;
}
